var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tipForm"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveChanges.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Title:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tip.title),expression:"tip.title"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{ 'form-control': true, 'is-invalid': _vm.errors.has('title') },attrs:{"type":"text","name":"title","id":"title","placeholder":"Title"},domProps:{"value":(_vm.tip.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.tip, "title", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid title. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"excerpt"}},[_vm._v("Text:")]),_c('editor',{staticClass:"wysiwyg-editor",attrs:{"api-key":"h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86","name":"text","id":"text","init":{
          plugins:
            'wordcount link image fullscreen lists textcolor colorpicker'
        }},model:{value:(_vm.tip.tip_text),callback:function ($$v) {_vm.$set(_vm.tip, "tip_text", $$v)},expression:"tip.tip_text"}})],1),_c('fieldset',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('legend',{staticClass:"col-form-label col-sm-2 pt-0"},[_vm._v("Media")]),_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tip.media_type),expression:"tip.media_type"}],staticClass:"form-check-input",attrs:{"type":"radio","name":"media_type","id":"media_type_video","value":"video"},domProps:{"checked":_vm._q(_vm.tip.media_type,"video")},on:{"change":function($event){return _vm.$set(_vm.tip, "media_type", "video")}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"media_type_video"}},[_vm._v("Video")])]),_c('div',{staticClass:"form-check",staticStyle:{"margin-left":"15px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tip.media_type),expression:"tip.media_type"}],staticClass:"form-check-input",attrs:{"type":"radio","name":"media_type","id":"media_type_image","value":"image"},domProps:{"checked":_vm._q(_vm.tip.media_type,"image")},on:{"change":function($event){return _vm.$set(_vm.tip, "media_type", "image")}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"media_type_image"}},[_vm._v("Image")])])]),_c('div',{staticClass:"row"},[_c('label',{attrs:{"for":"media_link"}},[_vm._v("Link:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tip.media_url),expression:"tip.media_url"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
                'form-control': true,
                'is-invalid': _vm.errors.has('media_link')
              },attrs:{"type":"text","name":"media_link","id":"media_link","placeholder":""},domProps:{"value":(_vm.tip.media_url)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.tip, "media_url", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid media link. ")])])])])]),_c('fieldset',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('legend',{staticClass:"col-form-label col-sm-2 pt-0"},[_vm._v("Featured")]),_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tip.featured),expression:"tip.featured"}],staticClass:"form-check-input",attrs:{"type":"radio","name":"featured","id":"featured_yes","value":"true"},domProps:{"checked":_vm._q(_vm.tip.featured,"true")},on:{"change":function($event){return _vm.$set(_vm.tip, "featured", "true")}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"media_type_video"}},[_vm._v("Yes")])]),_c('div',{staticClass:"form-check",staticStyle:{"margin-left":"15px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tip.featured),expression:"tip.featured"}],staticClass:"form-check-input",attrs:{"type":"radio","name":"featured","id":"featured_no","value":"false"},domProps:{"checked":_vm._q(_vm.tip.featured,"false")},on:{"change":function($event){return _vm.$set(_vm.tip, "featured", "false")}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"media_type_image"}},[_vm._v("No")])])])])])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"release_date"}},[_vm._v("Release Date:")]),_c('datepicker',{attrs:{"placeholder":"Select Date","name":"uniquename","input-class":"form-control"},model:{value:(_vm.tip.release_date),callback:function ($$v) {_vm.$set(_vm.tip, "release_date", $$v)},expression:"tip.release_date"}})],1),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group signup-carousel-buttons"},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Save")])])
}]

export { render, staticRenderFns }