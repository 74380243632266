<template>
  <div class="tipForm">
    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="title">Title:</label>
        <input
          type="text"
          v-model="tip.title"
          :class="{ 'form-control': true, 'is-invalid': errors.has('title') }"
          name="title"
          id="title"
          placeholder="Title"
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid title.
        </div>
      </div>

      <div class="form-group">
        <label for="excerpt">Text:</label>
        <editor
          api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86"
          name="text"
          id="text"
          v-model="tip.tip_text"
          class="wysiwyg-editor"
          :init="{
            plugins:
              'wordcount link image fullscreen lists textcolor colorpicker'
          }"
        ></editor>
      </div>

      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-2 pt-0">Media</legend>
          <div class="col-sm-10">
            <div class="row">
              <div class="form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  name="media_type"
                  id="media_type_video"
                  value="video"
                  v-model="tip.media_type"
                />
                <label for="media_type_video" class="form-check-label"
                  >Video</label
                >
              </div>
              <div class="form-check" style="margin-left: 15px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="media_type"
                  id="media_type_image"
                  value="image"
                  v-model="tip.media_type"
                />
                <label for="media_type_image" class="form-check-label"
                  >Image</label
                >
              </div>
            </div>
            <div class="row">
              <label for="media_link">Link:</label>
              <input
                type="text"
                v-model="tip.media_url"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('media_link')
                }"
                name="media_link"
                id="media_link"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a valid media link.
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-2 pt-0">Featured</legend>
          <div class="col-sm-10">
            <div class="row">
              <div class="form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  name="featured"
                  id="featured_yes"
                  value="true"
                  v-model="tip.featured"
                />
                <label for="media_type_video" class="form-check-label"
                  >Yes</label
                >
              </div>
              <div class="form-check" style="margin-left: 15px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="featured"
                  id="featured_no"
                  value="false"
                  v-model="tip.featured"
                />
                <label for="media_type_image" class="form-check-label"
                  >No</label
                >
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="form-group">
        <label for="release_date">Release Date:</label>
        <datepicker
          placeholder="Select Date"
          v-model="tip.release_date"
          name="uniquename"
          input-class="form-control"
        ></datepicker>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Editor from "@tinymce/tinymce-vue";

export default {
  methods: {
    saveChanges: function() {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };

        let tipRecord = this.tip;
        tipRecord.featured = this.tip.featured == "true";

        if (!isNaN(this.$route.params.id)) {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API + "/tips/" + this.$route.params.id,
              tipRecord,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Record updated successfully");
              }
            });
        } else if (this.$route.params.id == "add") {
          this.$http
            .post(process.env.VUE_APP_ROOT_API + "/tips", tipRecord, options)
            .then(response => {
              if (response.status === 200) {
                alert("Record created successfully");
              }
            });
        }
      }
    }
  },

  data: function() {
    return {
      tip: {}
    };
  },

  components: {
    datepicker: Datepicker,
    editor: Editor
  },

  created: function() {
    if (!isNaN(this.$route.params.id)) {
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "/tips/" + this.$route.params.id)
        .then(response => {
          this.tip = response.data;
        });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  }
};
</script>

<style></style>
